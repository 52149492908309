import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import Content from 'blocks/Content';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import Cta from 'components/Cta';

const Modules = ({ data }) => {
  const { item } = data;

  return (
    <Layout>
      <Seo
        image={item.titleImage}
        title={item.seoTitle}
        description={item.seoDescription}
      />

      <Title
        image={item.titleImage}
        alt={item.titleHeading}
        subtitle={item.titleSubheading}
        title={item.titleHeading}
      >
        {item.titleText}
      </Title>

      <Section>
        <Image image={item.blockImage} alt={item.blockHeading}>
          <Heading size="xl" as="h2">
            {item.blockHeading}
          </Heading>

          <Text>
            <span dangerouslySetInnerHTML={{ __html: item.blockText }} />
          </Text>
        </Image>
      </Section>

      <Section>
        <Content wide>
          <Cta image={item.logo} alt={item.ctaHeading}>
            <Heading size="xl" spacing="xs">
              {item.ctaHeading}
            </Heading>

            <Text color="gray-dark" spacing="lg">
              {item.ctaText}
            </Text>

            <ButtonGroup centerMobile>
              <Button as="a" color="tertiary" href={item.ctaDemoLink}>
                Probeer de demo-store
              </Button>

              <Button as="a" color="primary" outline href={item.ctaModuleLink}>
                Bekijk alle functies
              </Button>

              <Button as="a" color="primary" outline href={item.ctaTrialLink}>
                Start gratis!
              </Button>
            </ButtonGroup>
          </Cta>
        </Content>
      </Section>
    </Layout>
  );
};

Modules.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoDescription: PropTypes.string.isRequired,
      titleSubheading: PropTypes.string.isRequired,
      titleHeading: PropTypes.string.isRequired,
      titleText: PropTypes.string.isRequired,
      titleImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      blockHeading: PropTypes.string.isRequired,
      blockText: PropTypes.string.isRequired,
      blockImage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      ctaHeading: PropTypes.string.isRequired,
      ctaText: PropTypes.string.isRequired,
      ctaDemoLink: PropTypes.string.isRequired,
      ctaModuleLink: PropTypes.string.isRequired,
      ctaTrialLink: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query modulesItem($slug: String!) {
    item: modules(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      titleSubheading
      titleHeading
      titleText
      titleImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      blockHeading
      blockText
      blockImage {
        sharpImage: childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        publicURL
      }
      ctaHeading
      ctaText
      ctaDemoLink
      ctaModuleLink
      ctaTrialLink
      logo {
        sharpImage: childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
        publicURL
      }
    }
  }
`;

export default Modules;
